import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import './news.less';
import Nav from "../../components/nav/nav.jsx";
import Load from "../../components/load/load";
import timeIcon from '../../assets/icon_newsTime.png';
// import newsIcon from '../../assets/icon_news1.png';
import Footer from '../../components/footer/footer';
import { webNewsDetail, webNewContent } from '../../api/api';

class newsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            homeNav: false,
            newsDetail: {},
            webConfig: JSON.parse(localStorage.getItem("webConfig")) || null,
            menuId: localStorage.getItem("newsId") || null,
            newsContent: [],  //最新内容
            loading: true
        }
    }
    componentDidMount() {
        window.scrollTo(0,0);
        this.getNewContent();
        this.getData(this.props.match.params.id)
    }
    async getNewContent() {
        let parmas = {
            data: {
                menu_id: this.state.menuId
            }
        }
        let res = await webNewContent(parmas);
        this.setState({
            newsContent: res.data.data
        })
    }
    async getData(id) {
        let parmas = {
            id: id
        }
        let res = await webNewsDetail(parmas);
        setTimeout(() => {
            this.setState({
                newsDetail: res.data.data,
                loading: false
            }) 
        }, 1000);
    }
    navTo(id) {
        this.getData(id);
    }
    scrollLink = (data) => {
        this.props.history.push({pathname:'/',query: {name: data}});
    }
    render() {
        let content;
        if( !this.state.loading ) {
            content = (
                <div className="news">
                    <Helmet>
                        <title>{this.state.webConfig.name} - 动态详情</title>
                        <meta name="Keywords" content={this.state.webConfig.keyword} />
                        <meta name="Description" content={this.state.webConfig.description} />
                    </Helmet>
                    <Nav homeNav={this.state.homeNav} scrollLink={this.scrollLink} />
                    <div className="newsBox w1200">
                        <div className="newsBoxfl fl">
                            <div className="news_fl">
                                <dl className="dl_news details_til"> 
                                    <dd className="overp"><div>{this.state.newsDetail.title}</div></dd> 
                                    <dt className="dt2 dt2_style">
                                        <div className="icon_newsTime newsTime_icon">
                                            <img src={timeIcon} alt=""/>
                                            <span>{this.state.newsDetail.created_at}</span>
                                        </div>
                                        {/* <div class="icon_kuaijing kuaijing_icon">
                                            <img src={newsIcon} alt=""/>
                                            <span>企业微信 会话存档</span>
                                        </div> */}
                                    </dt>
                                </dl>
                                <div className="newsdetails_box">
                                    <div dangerouslySetInnerHTML={{__html:this.state.newsDetail.content}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="newsRg fr">
                            <div className="news_fr">
                                <ul className="ul_newsfr">
                                    <li>
                                        <p className="list_p">最新内容</p>
                                        {
                                            this.state.newsContent.map(item => {
                                                return (
                                                    <div 
                                                        className="list_con" 
                                                        key={item.id}
                                                        onClick={this.navTo.bind(this, item.id)}
                                                    >
                                                        <dl className="dl_listCon">
                                                            <dd>
                                                                <i></i>
                                                                <div>{item.title}</div>
                                                            </dd>
                                                            <dt className="dt2">
                                                                <div className="icon_newsTime">
                                                                    <img src={timeIcon} alt=""/>
                                                                    <span>{item.created_at}</span>
                                                                </div>
                                                            </dt>
                                                        </dl>
                                                    </div>
                                                )
                                            })
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    <Footer />
                </div>
            )
        }else {
            content = (
                <Load />
            )
        }
        return (
            <div>
                {content}
            </div>
        )
    }
}

export default newsDetail;