import React from "react";
import { Helmet } from 'react-helmet';

import PcSwiper from "../../components/pc-swiper/pcSwiper";
import "./home.less";
import { Player } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css";
import Load from "../../components/load/load";
import Nav from "../../components/nav/nav.jsx";
import Footer from '../../components/footer/footer';
import SlideFixed from '../../components/slide-fixed/slidefixed';
import { webIndex } from '../../api/api';


import deployIcon from "../../assets/deploy-icon.png";
import pcIcon from "../../assets/pc-icon.png";
import safeIcon from "../../assets/safe-icon.png";
import serverIcon from "../../assets/server-icon.png";
// import storeIcon from "../../assets/store-icon.png";
// import fissIcon from "../../assets/fission-icon.png";
// import luckyIcon from "../../assets/lucky-icon.png";
// import redIcon from "../../assets/red-icon.png";
// import shareFiss from "../../assets/sharefiss-icon.png";
import arrowIcon from "../../assets/arrow.png";
import playIcon from "../../assets/play-icon.png";
import closeIcon from "../../assets/close-icon.png";
import readMore from "../../assets/read-more.png";
import fissTitle from "../../assets/fission-title.png";
import fissActive from "../../assets/fission-active-title.png";
import luckyTitle from "../../assets/lucky-title.png";
import luckyActive from "../../assets/lucky-active-title.png";
import redTitle from "../../assets/red-title.png";
import redActive from "../../assets/red-active-title.png";
// import gouIcon from "../../assets/gou-icon.png";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import icon4 from "../../assets/icon4.png";
import icon5 from "../../assets/icon5.png";
import icon6 from "../../assets/icon6.png";
import icon7 from "../../assets/icon7.png";
import icon8 from "../../assets/icon8.png";

export default class home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeData: {
        list: ["", "", ""],
        scrm: [{
          id: 1,
          title: '如何让销售监管一步到位？',
          desc: '如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？'
        }, {
          id: 2,
          title: '如何让销售监管一步到位？',
          desc: '如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？'
        }, {
          id: 3,
          title: '如何让销售监管一步到位？',
          desc: '如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？'
        }, {
          id: 4,
          title: '如何让销售监管一步到位？',
          desc: '如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？'
        }],
        future: {
          tarbar: ['裂变拓客', '抽奖引流', '红包裂变']
        },
        storeScrm: [{
          icon: icon1
        }, {
          icon: icon2
        }, {
          icon: icon3
        }, {
          icon: icon4
        }, {
          icon: icon5
        }, {
          icon: icon6
        }, {
          icon: icon7
        }, {
          icon: icon8
        }]
      },
      swiperTar: [{
        title: '微信',
        spantitle: '官方服务商',
        subtitle: '私有化部署系统 独立品牌运作',
        icon: deployIcon
      }, {
        title: '10+',
        spantitle: '年门店运营',
        subtitle: '私有化部署系统 独立品牌运作',
        icon: pcIcon
      }, {
        title: '全网',
        spantitle: '营销方案',
        subtitle: '私有化部署系统 独立品牌运作',
        icon: safeIcon
      }, {
        title: '强大',
        spantitle: '研发实力',
        subtitle: '私有化部署系统 独立品牌运作',
        icon: serverIcon
      }],
      futureTar: [{
        title: '裂变拓客',
        icon: fissTitle,
        selectIcon: fissActive
      }, {
        title: '抽奖引流',
        icon: luckyTitle,
        selectIcon: luckyActive
      }, {
        title: '红包裂变',
        icon: redTitle,
        selectIcon: redActive
      }],
      futureCurrent: 0,  //未来店商选中
      shareCurrent: 0,
      isShow: false,
      width: 1200,  //窗口大小
      menu_list: [],  //导航栏
      banner: [],  //轮播图
      spreadLabel: [],  //四个标签
      webConfig: {},  //网站配置
      scrollTop: 100,  //滚动高度
      pathIndex: 0,  //导航选中
      videoSrc: '', //视频播放地址
      futureContent: [],  //未来店商对应显示内容
      shareContent: [],  //共享店铺对应显示内容
      loading: true,
      scrollName: '',  //跳转标签名
    }
  }
  componentWillMount() {
    this.getData();
  }
  componentDidMount() {
    //监听窗口大小
    let windows = window;
    window.addEventListener("resize", (e) => {
      this.setState({ width: windows.innerWidth });
    });
    // 监听滚动
    window.addEventListener("scroll", this.doScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.doScroll);
  }
  doScroll = (e) => {
    if( !this.state.loading ) {
      let elementTopArr = []
      this.state.menu_list.forEach((item, index) => {
        if( index === 0 || index === 1 ) {
          elementTopArr.push(document.getElementById(item.uri).offsetTop);
        }else {
          elementTopArr.push(document.getElementById(item.uri).offsetTop - 66);
        }
      })
      localStorage.setItem('elementTop', JSON.stringify(elementTopArr));
    }
    let scrollTop = e.srcElement.scrollingElement.scrollTop;
    let elementTop = JSON.parse(localStorage.getItem('elementTop'));
    if( this.state.width > 768 ) {
      let pathIndex;
      if( scrollTop < elementTop[1] ) {
        pathIndex = 0
      }else if( scrollTop >= elementTop[1] && scrollTop < elementTop[2] ) {
        pathIndex = 1
      }else if( scrollTop >= elementTop[2] && scrollTop < elementTop[3] ) {
        pathIndex = 2
      }else if( scrollTop >= elementTop[3] && scrollTop < elementTop[4] ) {
        pathIndex = 3
      }else if( scrollTop >= elementTop[4] && scrollTop < elementTop[5] ) {
        pathIndex = 4
      }else if( scrollTop >= elementTop[5] && scrollTop < elementTop[6] ) {
        pathIndex = 5
      }else if( scrollTop >= elementTop[6] && scrollTop < (elementTop[7] - 380)) {
        pathIndex = 6
      }else {
        pathIndex = 7
      }
      this.setState({
        scrollTop,
        pathIndex
      });
    }else {
      this.setState({
        scrollTop
      });
    }
  }
  async getData() {
    let res = await webIndex();
    let { banner, config, menu_list } = res.data.data
    menu_list.unshift({
      title: '首页',
      uri: '#index'
    })
    localStorage.setItem('webConfig', JSON.stringify(config))
    localStorage.setItem('menuList', JSON.stringify(menu_list))
    this.setState({
      menu_list,
      banner,
      spreadLabel: config.spread_label,
      webConfig: config
    })
    setTimeout(() => {
      this.setState({
        loading: false
      })
      this.scrollLink(this.state.scrollName);
    }, 1000);
    if(this.props.location.query) {
      this.scrollLink(this.props.location.query.name)
    }
  }
  videoSwitch(video) {
    this.setState({
      videoSrc: video,
      isShow: true
    });
  }
  // 未来店商tar
  futureClick(index, contents) {
    this.setState({
      futureCurrent: index,
      futureContent: contents
    });
  }
  // 共享店铺tar
  shareTarClick(index, contents) {
    this.setState({
      shareCurrent: index,
      shareContent: contents
    });
  }
  navVideoTo(id) {
    this.props.history.push('/video/' + id);
  }
  navNewsTo(id) {
    localStorage.setItem("newsId", id);
    this.props.history.push('/news');
  }
  scrollLink = (data) => {
    this.setState({
      scrollName: data
    });
    if (data) {
      const anchorElement = document.getElementById(data);
      if (anchorElement) {
        try {
          anchorElement.scrollIntoView({ behavior: "smooth", block: "start" });
        } catch (e) {
          anchorElement.scrollIntoView();
        }
      }
    }
  }
  render() {
    // let saasIcon = null;
    // let shareIcon = null;
    // saasIcon = this.state.current === 0 ? fissIcon : ( this.state.current === 1 ? luckyIcon : redIcon );
    // shareIcon = this.state.shareCurrent === 0 ? shareFiss : ( this.state.shareCurrent === 1 ? luckyIcon : redIcon );
    let isContent, isVideo, isFixed;
    if( this.state.isShow ) {
      isVideo = (
        <div className="video-fixed">
          <div className="video-box">
            <div className="closeVideo" onClick={() => {this.setState({ isShow: false })}}>
              <img src={closeIcon} alt="" />
            </div>
            <Player 
              autoPlay
              aspectRatio="4:1"
              src={this.state.videoSrc}
            />
          </div>
        </div>
      )
    }
    if( !this.state.loading ) {
      isContent = (
        <div>
          <Helmet>
            <title>{this.state.webConfig.name} - 首页</title>
            <meta name="Keywords" content={this.state.webConfig.keyword} />
            <meta name="Description" content={this.state.webConfig.description} />
          </Helmet>
          <Nav 
            menuList={this.state.menu_list}
            homeNav 
            scrollTop={this.state.scrollTop} 
            pathIndex={this.state.pathIndex}
            scrollLink={this.scrollLink} 
          /> 
          <div className="container">
            {/* 轮播 */}
            <div id="#index">
              <PcSwiper banner={this.state.banner}/>
              {/* 四个标签 */}
              <div className="swiper-tar">
                <div className="swiper-tar-list w1200">
                  {
                    this.state.spreadLabel.map((item, index) => {
                      return (
                        <div className="swiper-tar-item" key={index}>
                          <img src={item.logo} alt=""/>
                          <div className="info">
                            <div className="title">{item.title}</div>
                            <div className="sub_title">{item.intro}</div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            {
              this.state.menu_list.map((item) => {
                if( item.uri === '#high-light') {
                  return (
                    <div id={item.uri} key={item.id} className="scrm-intro">
                      {/* 亮点揭秘 */}
                      <div className="w1200">
                        <div className="home-title">{item.title}</div>
                        {/* <div className="home-sub">标准化产品解决行业痛点，定制化功能破解个体瓶颈</div> */}
                        <div className="scrm-list">
                          {
                            item.contents.map(item => {
                              return (
                                <div className="scrm-item" key={item.id}>
                                  <div className="video-box">
                                    <img src={item.cover} alt="" />
                                    <div className="videoTitle">{item.title}</div>
                                    <div className="play-video" onClick={this.videoSwitch.bind(this, item.content)}>
                                      <img src={playIcon} alt="" />
                                    </div>
                                  </div>
                                  <div className="title">{item.title}</div>
                                  <div className="desc">{item.intro}</div>
                                </div>
                              )
                            })
                          }
                        </div>
                        <div className="read-more">
                          <div onClick={this.navVideoTo.bind(this, item.id)}>
                            <span>查看所有产品视频</span>
                            <i className="saasIcon">&#xe6c7;</i>
                          </div>
                        </div>
                      </div>
                      {isVideo}
                    </div>
                  )
                }else if( item.uri === '#future-business' ) {
                  return (
                    <div id={item.uri} key={item.id} className="future-intro">
                      {/* 未来店商SaaS云平台 */}
                      <div className="w1200" data-aos="fade-up" data-aos-once>
                        <div className="home-title">{item.title}</div>
                        {/* <div className="home-sub">{item.title}SaaS云平台</div> */}
                        <div className="future-box">
                          <div className="future-tar">
                            {
                              item.children_menu.map((item, index) => {
                                return (
                                  <div 
                                    className={["future-tar-item", this.state.futureCurrent === index ? 'active':''].join(' ')} 
                                    key={index} 
                                    onClick={this.futureClick.bind(this, index, item.contents)}
                                  >
                                    {/* <img src={this.state.current === index ? item.selectIcon : item.icon} alt=""/> */}
                                    <span>{item.title}</span>
                                  </div>
                                )
                              })
                            }
                          </div>
                          {
                            item.children_menu.map((data, index) => {
                              if( this.state.futureCurrent === index ) {
                                return (
                                  <div className="future-item" key={index}>
                                    {
                                      data.contents.map((content, cindex) => {
                                        return(
                                          <img src={content.content} key={cindex} alt=""/>
                                        )
                                      })
                                    }
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )
                }else if( item.uri === '#share-shop' ) {
                  return (
                    <div id={item.uri} key={item.id} className="future-intro share-intro">
                      {/* 共享店铺 */}
                      <div className="w1200" data-aos="fade-up" data-aos-once>
                        <div className="home-title">{item.title}</div>
                        {/* <div className="home-sub">帮助单店年增长300万的营业额</div> */}
                        <div className="future-box">
                          <div className="future-tar">
                            {
                              item.children_menu.map((item, index) => {
                                return (
                                  <div 
                                    className={["future-tar-item", this.state.shareCurrent === index ? 'active':''].join(' ')} 
                                    key={index} 
                                    onClick={this.shareTarClick.bind(this, index, item.contents)}
                                  >
                                    {/* <img src={this.state.shareCurrent === index ? item.selectIcon : item.icon} alt=""/> */}
                                    <span>{item.title}</span>
                                  </div>
                                )
                              })
                            }
                          </div>
                          {
                            item.children_menu.map((data, index) => {
                              if( this.state.shareCurrent === index ) {
                                return (
                                  <div className="future-item" key={index}>
                                    {
                                      data.contents.map((content, cindex) => {
                                        return(
                                          <img src={content.content} key={cindex} alt=""/>
                                        )
                                      })
                                    }
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )
                }else if( item.uri === '#shop-scrm' ) {
                  return (
                    <div id={item.uri} key={item.id} className="store-intro">
                      {/* 门店SCRM */}
                      <div className="w1200">
                        <div className="home-title">{item.title}</div>
                        {/* <div className="home-sub">帮助单店年增长300万的营业额</div> */}
                        <div className="store-img">
                          {
                            item.contents.map((content, index) => {
                              return(
                                <img src={content.content} key={index} alt=""/>
                              )
                            })
                          }
                        </div>
                      </div>
                      {/* <div className="w1200">
                        <div className="home-title">门店SCRM</div>
                        <div className="home-sub">帮助单店年增长300万的营业额</div>
                        <div className="store-box">
                          <div className="store-left" data-aos="fade-right">
                            {
                              this.state.homeData.scrm.map((item, index) => {
                                return (
                                  <div className="store-item" key={index}>
                                    <img src={gouIcon} alt=""/>
                                    <div className="info">
                                      <div className="title">{item.title}</div>
                                      <div>{item.desc}</div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                          <div className="store-right" data-aos="fade-left">
                            <img src={storeIcon} alt=""/>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  )
                }else if( item.uri === '#customer-fission' ) {
                  return (
                    <div id={item.uri} key={item.id} className="store-scrm">
                      {/* 爆客增长 */}
                      <div className="w1200" data-aos="fade-up" data-aos-once>
                        <div className="home-title">{item.title}</div>
                        {/* <div className="home-sub">帮助单店年增长300万的营业额</div> */}
                        <div className="store-scrm-list">
                          {
                            item.contents.map((item, index) => {
                              return (
                                <div 
                                  className="store-scrm-item" 
                                  key={index} 
                                  data-aos="zoom-in" data-aos-once
                                >
                                  <img className="img-bg" src={item.cover} alt=""/>
                                  <div className="img-box">
                                    <img src={item.cover} alt=""/>
                                  </div>
                                  <div className="title">{item.title}</div>
                                  <div className="subtitle">{item.intro}</div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )
                }else if( item.uri === '#news' ) {
                  return (
                    <div id={item.uri} key={item.id} className="dynaic">
                      {/* 最新动态 */}
                      <div className="w1200" data-aos="fade-up" data-aos-once>
                        <div className="home-title">{item.title}</div>
                        {/* <div className="home-sub">高频次产品更新与升级服务</div> */}
                        <div className="dynaic-list">
                          {
                            item.contents.map(item => {
                              return (
                                <div className="dynaic-item" key={item.id} onClick={() => { this.props.history.push('/newsDetail/' + item.id) }}>
                                  <div className="dynaic-date">
                                    <div className="date">12-22</div>
                                    <div>2020</div>
                                  </div>
                                  <div className="dynaic-info">
                                    <div className="title">{item.title}</div>
                                    <div className="subtitle">{item.intro}</div>
                                  </div>
                                  <div className="dynaic-button">查看详情</div>
                                </div>
                              )
                            })
                          }
                        </div>
                        <div className="goMore" onClick={this.navNewsTo.bind(this, item.id)}>
                          <img src={arrowIcon} alt=""/>
                        </div>
                      </div>
                    </div>
                  )
                }else if( item.uri === '#about-us' ) {
                  return (
                    <div id={item.uri} key={item.id}>
                      <Footer />
                    </div>
                  )
                }
              })
            }
          </div>
        </div>
      )
      isFixed = (
        <SlideFixed />
      )
    }else {
      isContent = (
        <Load />
      )
    }
    return (
      <div>
        {isContent}
        {isFixed}
      </div>
    );
  }
}
