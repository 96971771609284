import React, { Component } from "react";
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.less';

import './pcSwiper.less';

SwiperCore.use([Autoplay]);
// import bannerPc from '../../assets/banner1.png';
// import bannerPhone from '../../assets/banner-phone.png';
// import bannerBg1 from '../../assets/banner-bg1.png';
// import phoneBg from '../../assets/phone-bg.png';
// import phoneItem1 from '../../assets/phone-item1.png';
// import phoneItem2 from '../../assets/phone-item2.png';
// import phoneItem3 from '../../assets/phone-item3.png';
// import phoneItem4 from '../../assets/phone-item4.png';
// import phoneItem5 from '../../assets/phone-item5.png';

class PcSwiper extends Component {
    state = {}
    render() {
        return (
            <Swiper 
                autoplay
                loop={true}
            >
                {this.props.banner.map((item, index) => {
                    return (
                        <SwiperSlide
                            className="home-silde" 
                            key={index}
                        >  
                            <img className="banner-pc" src={item} alt=""/>
                            {/* <img className="banner-phone" src={bannerPhone} alt=""/>
                            <div className="slide-fixed w1200">
                                <div className="slide-left">
                                    <div className="title">未来店商点亮揭秘</div>
                                    <div className="subtitle">未来店商点亮揭秘</div>
                                </div>
                                <div className="slide-right">
                                    <img src={phoneBg} alt="" />
                                    <img className="phoneItem1" src={phoneItem1} alt="" />
                                    <img className="phoneItem2" src={phoneItem2} alt=""/>
                                    <img className="phoneItem3" src={phoneItem3} alt=""/>
                                    <img className="phoneItem4" src={phoneItem4} alt="" />
                                    <img className="phoneItem5" src={phoneItem5} alt="" />
                                </div>
                            </div> */}
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        )
    }
}

export default PcSwiper;