import React, { Component } from "react";
import './slidefixed.less'
import contact1 from '../../assets/icon_contact1.png';
import contact2 from '../../assets/icon_contact2.png';
import contact3 from '../../assets/icon_contact3.png';

class slideFixed extends Component {
    state = {
        webConfig: JSON.parse(localStorage.getItem('webConfig')) || null
    }
    render() {
        return (
            <div className="contact-fixed">
                <ul className="contact-ul">
                    <li className="icon_contact1">
                        <img src={contact1} alt="" />
                        <div className="tan_wei1">
                        <div className="tan_sanjiao"></div>
                        <img src={this.state.webConfig.customer_service_qrcode} alt=""/>
                        <p className="center">扫码加微信咨询</p>
                        </div>
                    </li>
                    <li className="icon_contact2">
                        <img src={contact2} alt="" />
                        <div className="tan_wei2">
                        <div className="tan_sanjiao"></div>
                        <p className="p_tel">{this.state.webConfig.contact}</p>
                        </div>
                    </li>
                    <li className="icon_contact3">
                        <img src={contact3} alt="" />
                        <div className="tan_wei3">
                        <div className="tan_sanjiao"></div>
                            <img src={this.state.webConfig.official_account_qrcode} alt=""/>
                            <p className="center">微信扫码关注</p>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}

export default slideFixed;