/*
 * @Author: your name
 * @Date: 2021-05-04 10:34:16
 * @LastEditTime: 2021-05-04 18:20:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \website\src\api\apiURL.js
 */
let publicIp = process.env.NODE_ENV === 'development' ? 'https://api.qietongvip.com' : 'https://api.qietong.net'
console.log('process.env :>> ', process.env);
if( window.location.host == 'web.qietongvip.com'){
    publicIp = 'https://api.qietongvip.com'
}
//网站配置
export const webConfig = `${publicIp}/api/agent/website/config`;

//首页
export const webIndex = `${publicIp}/api/agent/website/index`;

//文章列表
export const webNews = `${publicIp}/api/agent/website/contents`;

//文章详情
export const webNewsDetail = `${publicIp}/api/agent/website/contents`;

//获取验证码
export const webCode = `${publicIp}/api/agent/website/code`;

//保存表单
export const webRegister = `${publicIp}/api/agent/website/register`;

//最新的内容列表
export const webNewContent = `${publicIp}/api/agent/website/new-contents`;