import React, { Component } from "react";
import "./footer.less";
import phone from "../../assets/phone.png";
import closeIcon from "../../assets/close-icon.png";
import { webCode, webRegister } from '../../api/api';

class Footer extends Component {
    state = {
        menuList: JSON.parse(localStorage.getItem('menuList')) || null,
        webConfig: JSON.parse(localStorage.getItem('webConfig')) || null,
        phoneName: '',  //称呼
        phoneNumber: '',  //手机号
        phoneCode: '',  //验证码
        count: 0,  //倒计时
        phoneShow: false,  //手机绑定弹窗
    }
    async getCode() {
        if( this.state.phoneNumber === '' ) {
            alert("请输入手机号");
            return;
        }
        let count = this.state.count;
        if( count > 0 ) {
            return;
        }
        let parmas = {
            data: {
              phone: this.state.phoneNumber,
              type: 5
            }
        }
        let res = await webCode(parmas);
        if(res.data.code === 200) {
            alert(res.data.data.msg);
            let timer = null;
            let minus = 60;
            timer = setInterval(() => {
                minus--;
                if( minus === 0 ) {
                    clearInterval(timer);
                }
                this.setState({
                    count: minus
                })
            }, 1000);
        }
    }
    async submit() {
        if( this.state.phoneNumber === '' ) {
            alert("请输入手机号");
            return;
        }
        if( this.state.phoneCode === '' ) {
            alert("请输入验证码");
            return;
        }
        if( this.state.phoneName === '' ) {
            alert("请输入您的称呼");
            return;
        }
        let parmas = {
            data: {
              name: this.state.phoneName,
              phone: this.state.phoneNumber,
              code: this.state.phoneCode
            }
        }
        let res = await webRegister(parmas);
        if(res.data.code === 200) {
            alert(res.data.data);
            this.setState({
                phoneShow: false
            });
        }
    }
    yzPhone() {
        let myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!myreg.test(this.state.phoneNumber)) {
            alert("请输入正确的手机号码");
            return;
        } else {
            this.setState({ phoneShow: true });
        }
    }
    render() {
        let phoneBox;
        if( this.state.phoneShow ) {
            phoneBox = (
                <div className="phone-box">
                    <div className="closeBtn" onClick={() => {this.setState({phoneShow: false})}}>
                        <img src={closeIcon} alt=""/>
                    </div>
                    <div className="phone-title">立即申请试用版本</div>
                    <input 
                        type="text" 
                        placeholder="请输入您的称呼" 
                        value={this.state.phoneName}
                        onChange={(e)=>{this.setState({ phoneName: e.target.value })}}
                    />
                    <input 
                        type="text" 
                        placeholder="请输入手机号" 
                        maxLength="11"
                        value={this.state.phoneNumber}
                        onChange={(e)=>{this.setState({ phoneNumber: e.target.value })}}
                    />
                    <div className="yzm">
                        <input 
                            type="text" 
                            placeholder="请输入验证码"
                            value={this.state.phoneCode}
                            onChange={(e)=>{this.setState({ phoneCode: e.target.value })}}
                        />
                        <div 
                            className={["yzm-btn", this.state.count > 0 ? 'active':''].join(' ')} 
                            onClick={this.getCode.bind(this)}>
                            { this.state.count > 0 ? this.state.count : '获取验证码' }
                        </div>
                    </div>
                    <div className="submit" onClick={this.submit.bind(this)}>确认提交</div>
                </div>
            )
        }
        return (
            <div className="footer">
                <div className="email">
                    <div className="w1200">
                        <div className="email-box">
                            <input 
                                type="text" 
                                maxLength="11" 
                                placeholder="请填写手机号码" 
                                value={this.state.phoneNumber}
                                onChange={(e)=>{this.setState({ phoneNumber: e.target.value })}}
                            />
                            <div onClick={this.yzPhone.bind(this)}>申请试用</div>
                        </div>
                        {/* <div className="start-btn">立即创建使用</div> */}
                    </div>
                </div>
                <div className="menu">
                    <div className="menu-flex w1200">
                        <div className="about">
                            <div className="menuTitle">关于我们</div>
                            <div className="menu-subtitle">ABOUT US</div>
                            {
                                this.state.menuList.map(item => {
                                    if(item.uri === "#about-us") {
                                        return (
                                            <div className="about-content" key={item.id}>
                                                {item.contents[0].content}
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className="partiner">
                            <div>
                                <div className="menuTitle">合作伙伴</div>
                                <div className="menu-subtitle">COOPERATIVE PARTNER</div>
                                <div className="partiner-list">
                                    {
                                        this.state.webConfig.cooperative_partner_config.map((item, index) => {
                                            return (
                                                <img src={item.logo} key={index} alt=""/>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="contact">
                            <div className="menuTitle">联系我们</div>
                            <div className="menu-subtitle">CONTACT US</div>
                            <div className="contact-box">
                                <div className="ewm">
                                    <img src={this.state.webConfig.customer_service_qrcode} alt=""/>
                                    <div className="title">扫码咨询</div>
                                </div>
                                <div className="phone">
                                    <img src={phone} alt=""/>
                                    <span>{this.state.webConfig.contact}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyRight w1200">
                        <img src={this.state.webConfig.logo} alt=""/>
                        <div>
                            <span>{this.state.webConfig.copyright}</span>
                            <span>工信部备案号: <a href="https://beian.miit.gov.cn/" target="_blank">{this.state.webConfig.registration_number}</a></span>
                        </div> 
                    </div>
                </div>
                {phoneBox}
            </div>
        )
    }
}

export default Footer;