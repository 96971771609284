import axios from 'axios';
import * as apiUrl from './apiURL';

/**
 *  接口请求数据时执行的方法
 *  接受参数为请求的路径apiUrl、请求接口配置参数configObj
 *
 * @param {String} apiUrl            用户传入的请求路径
 * @param {Object} options        用户传入的接口参数
 */
function getDataFromServer(apiUrl, options) {
    //用户传入的接口配置参数
    let method = options.method || 'GET';
    let params = options.data || {};
    if( localStorage.getItem('agentId') ) {
        params.agent_id = localStorage.getItem('agentId');
    }
    /**
     * 返回的Promise对象含有then、catch方法
     */
    return new Promise(function (resolve, reject) {
        axios({
            url: apiUrl,
            method: method,
            params: params,
            timeout: 5000,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if(response){
                if (response.data && response.data.code) {
                    resolve(response);
                }else {
                    resolve(response);
                }
            }else {
                //处理特殊的情况就是response返回什么也没有
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
    })
}

function get(apiUrl, options = {}) {
    options.method = 'get';
    return getDataFromServer(apiUrl, options);
}


// 网站配置
export function webConfig(options) {
    return get(apiUrl.webConfig, options);
}

//首页
export function webIndex(options) {
    return get(apiUrl.webIndex, options);
}

//文章列表
export function webNews(options) {
    return get(apiUrl.webNews, options);
}

//文章详情
export function webNewsDetail(options) {
    return get(`${apiUrl.webNewsDetail}/${options.id}`, options);
}

//获取验证码
export function webCode(options) {
    return get(apiUrl.webCode, options);
}

//保存表单
export function webRegister(options) {
    return get(apiUrl.webRegister, options);
}

//最新的内容列表
export function webNewContent(options) {
    return get(apiUrl.webNewContent, options);
}