import React, { Component } from 'react';
import './load.less';

class Load extends Component {
    state = {}
    render() {
        return (
            <div className="load-box">
                <div>
                    <div className="k-line2 k-line12-1" ></div>
                    <div className="k-line2 k-line12-2" ></div>
                    <div className="k-line2 k-line12-3" ></div>
                    <div className="k-line2 k-line12-4" ></div>
                    <div className="k-line2 k-line12-5" ></div>
                    <div className="k-line2 k-line12-6" ></div>
                    <div className="k-line2 k-line12-7" ></div>
                    <div className="k-line2 k-line12-8" ></div>
                </div>
            </div>
        )
    }
}

export default Load;