import React from "react";
import "./nav.less";
import menuIcon from "../../assets/menu-icon.png";
import closeIcon from "../../assets/menu-close.png";
// import logoIcon from "../../assets/logo.png";
// import logoWhiteIcon from "../../assets/logo-white.png";

class nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
      pathIndex: 0,
      isShow: false,
      width: 1200,
      homeNav: this.props.homeNav,  //是否为首页导航
      webConfig: JSON.parse(localStorage.getItem('webConfig')) || null,
      menuList: JSON.parse(localStorage.getItem('menuList')) || []
    };
  }
  componentDidMount() {
    //监听窗口大小
    let windows = window;
    window.addEventListener("resize", (e) => {
      this.setState({ width: windows.innerWidth });
    });
    // // 监听滚动
    // window.addEventListener("scroll", (e) => {
    //   let scrollTop = e.srcElement.scrollingElement.scrollTop;
    //   if( this.state.width > 768 ) {
    //     let pathIndex;
    //     if( scrollTop < 630 ) {
    //       pathIndex = 0
    //     }else if( scrollTop >= 630 && scrollTop < 1200) {
    //       pathIndex = 1
    //     }else if( scrollTop >= 1200 && scrollTop < 2157 ) {
    //       pathIndex = 2
    //     }else if( scrollTop >= 2157 && scrollTop < 3114 ) {
    //       pathIndex = 3
    //     }else if( scrollTop >= 3114 && scrollTop < 3872 ) {
    //       pathIndex = 4
    //     }else if( scrollTop >= 3872 && scrollTop < 4616 ) {
    //       pathIndex = 5
    //     }else if( scrollTop >= 4616 && scrollTop < 4900 ) {
    //       pathIndex = 6
    //     }else {
    //       pathIndex = 7
    //     }
    //     this.setState({
    //       scrollTop,
    //       pathIndex
    //     });
    //   }else {
    //     this.setState({
    //       scrollTop
    //     });
    //   }
    // });
  }
  selectNav(e,index,anchorName) {
    e.nativeEvent.stopImmediatePropagation();
    this.props.scrollLink(anchorName);
    this.setState({
      pathIndex: index,
      isShow: false
    });
  }
  showNavSelect() {
    this.setState({
      isShow: !this.state.isShow,
    });
  }
  render() {
    let model = null;
    if (this.state.isShow) {
      model = (
        <div className="leftModel" >
          <div className="modelContent">
            <div className="modalHeader">
              <img
                className="logo"
                src={this.state.webConfig.logo}
                alt=""
              />
              <img
                className="close"
                src={closeIcon}
                alt=""
                onClick={this.showNavSelect.bind(this)}
              />
            </div>
            <div className="modelNav">
              {this.state.menuList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={[
                      "nav-box",
                    ].join(" ")}
                    onClick={(e) => {
                      this.selectNav(e,index,item.uri);
                    }}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        className={[
          "header",
          this.props.scrollTop > 450 ? "header-bg" : "",
          this.state.homeNav ? "" : "header-bg"
        ].join(" ")}
      >
        <div className="nav w1200">
          <img
            src={this.state.webConfig.logo}
            alt=""
            className="logo"
          />
          <div className="nav-content">
            {this.state.menuList.map((item, index) => {
              return (
                <div
                  key={index}
                  className={[
                    "nav-content-box",
                    this.props.pathIndex === index && this.state.homeNav ? "active" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    this.selectNav(e,index,item.uri);
                  }}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          <img
            src={menuIcon}
            alt=""
            className="icon"
            onClick={this.showNavSelect.bind(this)}
          />
        </div>
        {model}
      </div>
    );
  }
}

export default nav;