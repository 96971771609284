import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import './news.less';
import Nav from "../../components/nav/nav.jsx";
import timeIcon from '../../assets/icon_newsTime.png';
// import newsIcon from '../../assets/icon_news1.png';
import Load from "../../components/load/load";
import Footer from '../../components/footer/footer';
import Pagecomponent from '../../components/page/page';
import { webNews, webNewContent } from '../../api/api';

class newsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            homeNav: false,
            menuId: localStorage.getItem("newsId") || null,
            currentPage: 1,  //页码
            newsList: [],  //新闻列表
            newsContent: [],  //最新内容
            totalPage: 1,  //总页数
            webConfig: JSON.parse(localStorage.getItem("webConfig")) || null,
            loading: true
        }
    }
    componentDidMount() {
        window.scrollTo(0,0);
        this.getNewContent();
        this.getData(1);
    }
    navTo(id) {
        this.props.history.push('/newsDetail/' + id);
    }
    scrollLink = (data) => {
        this.props.history.push({pathname:'/',query: {name: data}});
    }
    getCurrentPage(currentPage) {
        if( currentPage === this.state.currentPage ) {
            return;
        }
        this.setState({
            currentPage: currentPage
        });
        this.getData(currentPage);
    }
    async getNewContent() {
        let parmas = {
            data: {
                menu_id: this.state.menuId
            }
        }
        let res = await webNewContent(parmas);
        this.setState({
            newsContent: res.data.data
        })
    }
    async getData(currentPage) {
        let parmas = {
            data: {
                menu_id: this.state.menuId,
                per_page: 10,
                page: currentPage
            }
        }
        let res = await webNews(parmas);
        let { data, total } = res.data.data;
        setTimeout(() => {
            this.setState({
                newsList: data,
                totalPage: Math.ceil(total / 10),
                loading: false
            })
        }, 1000);
    }
    render() {
        let content;
        if( !this.state.loading ) {
            content = (
                <div className="news">
                    <Helmet>
                        <title>{this.state.webConfig.name} - 动态列表</title>
                        <meta name="Keywords" content={this.state.webConfig.keyword} />
                        <meta name="Description" content={this.state.webConfig.description} />
                    </Helmet>
                    <Nav homeNav={this.state.homeNav} scrollLink={this.scrollLink} />
                    <div className="newsBox w1200">
                        <div className="container-title">
                            <div className="title">新闻列表</div>
                            <div className="subtitle">NEWS LIST</div>
                        </div>
                        <div className="newsBoxfl fl">
                            <div className="news_fl">
                                {
                                    this.state.newsList.map(item => {
                                        return (
                                            <div className="news_flBox" key={item.id}>
                                                <dl className="dl_news" onClick={this.navTo.bind(this, item.id)}>
                                                    <div className="news_img">
                                                        <div>
                                                            <img src={item.cover} alt=""/>
                                                        </div>
                                                    </div>
                                                    <dd className="overp">
                                                        <div>{item.title}</div>
                                                    </dd>
                                                    <dt className="dt1">
                                                        <p>{item.intro}</p>
                                                    </dt>
                                                    <dt className="dt2">
                                                        <div className="icon_newsTime">
                                                            <img src={timeIcon} alt=""/>
                                                            <span>{item.created_at}</span>
                                                        </div>
                                                        {/* <div className="icon_kuaijing">
                                                            <img src={newsIcon} alt=""/>
                                                            <span>企业微信 会话存档</span>
                                                        </div> */}
                                                    </dt>
                                                </dl>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <Pagecomponent pageCallbackFn={this.getCurrentPage.bind(this)} totalPage={this.state.totalPage} currentPage={this.state.currentPage} />
                        </div>
                        <div className="newsRg fr">
                            <div className="news_fr">
                                <ul className="ul_newsfr">
                                    <li>
                                        <p className="list_p">最新内容</p>
                                        {
                                            this.state.newsContent.map(item => {
                                                return (
                                                    <div 
                                                        className="list_con" 
                                                        key={item.id}
                                                        onClick={this.navTo.bind(this, item.id)}
                                                    >
                                                        <dl className="dl_listCon">
                                                            <dd>
                                                                <i></i>
                                                                <div>{item.title}</div>
                                                            </dd>
                                                            <dt className="dt2">
                                                                <div className="icon_newsTime">
                                                                    <img src={timeIcon} alt=""/>
                                                                    <span>{item.created_at}</span>
                                                                </div>
                                                            </dt>
                                                        </dl>
                                                    </div>
                                                )
                                            })
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    <Footer />
                </div> 
            )
        }else {
            content = (
              <Load />
            )
        }
        return (
            <div>
                {content}
            </div>
        )
    }
}

export default newsList;