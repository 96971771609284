/*
 * @Author: your name
 * @Date: 2021-05-04 10:34:16
 * @LastEditTime: 2021-05-04 21:34:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \website\src\App.js
 */
import "./App.css";
import React, { Component } from "react";
// import { HashRouter as Router, Route } from "react-router-dom";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { webConfig } from "./api/api";
import AOS from "aos";
import "aos/dist/aos.css";
import Home from "./pages/home/home";
import Video from "./pages/video/video";
import NewsList from "./pages/news/newsList";
import NewsDetail from "./pages/news/newsDetail";
// import Footer from './components/footer/footer';
import "./assets/iconfont/icon.css";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 1200,
            webConfig: JSON.parse(localStorage.getItem("webConfig")) || null,
            loading: true,
        };
    }
    componentWillMount() {
        this.getData();
    }
    componentDidMount() {
        this.setState({ width: window.innerWidth });
        let windows = window;
        window.onhashchange = (e) => {
            // console.log(e)
        };
        window.addEventListener("resize", (e) => {
            // console.log(windows.innerWidth);
            this.setState({ width: windows.innerWidth });
        });
        AOS.init({
            offset: 200,
            duration: 1500,
            easing: "ease-in-sine",
            delay: 100,
        });
    }
    componentWillUnmount() {
        // window.removeEventListener("resize");
    }
    async getData() {
        // console.log(window.location.host)
        const domain = process.env.NODE_ENV == "development" ? "web.qietongvip.com" : window.location.host;
        let parmas = {
            data: {
                domain,
                /*    domain: "web.qietongvip.com", //测试环境
                // domain: window.location.host  //生产环境 */
            },
        };
        let res = await webConfig(parmas);
        if (res.data.code === 200) {
            localStorage.setItem("agentId", res.data.data.agent_id);
            this.setState({
                loading: false,
            });
        }
    }
    render() {
        // const { width } = useViewport();
        // console.log(width, "width");
        let loadShow = null;
        if (!this.state.loading) {
            loadShow = (
                // <Load />
                <HashRouter>
                    <div className="App">
                        <Switch>
                            <Route path="/" component={Home} exact></Route>
                            <Route path="/video/:id" component={Video}></Route>
                            <Route path="/news" component={NewsList}></Route>
                            <Route path="/newsDetail/:id" component={NewsDetail}></Route>
                            <Redirect to="/" />
                        </Switch>
                    </div>
                </HashRouter>
            );
        }
        return (
            <div>{loadShow}</div>
            // <Router>
            //   <div className="App">
            //     {/* {this.state.width} */}
            //     <Nav width={this.state.width}/>
            //     <Route path="/" component={Home} exact></Route>
            //     <Route path="/video" component={Video}></Route>
            //     <Route path="/news" component={NewsList}></Route>
            //     <Route path="/newsDetail" component={NewsDetail}></Route>
            //     <Footer />
            //   </div>
            // </Router>
        );
    }
}

export default App;
