import React, { Component } from "react";
import { Helmet } from 'react-helmet';

import "./video.less";
import { Player } from 'video-react';
import Load from "../../components/load/load";
import Nav from "../../components/nav/nav.jsx";
import Footer from '../../components/footer/footer';
import Pagecomponent from '../../components/page/page';

import "../../../node_modules/video-react/dist/video-react.css";
import playIcon from "../../assets/play-icon.png";
import closeIcon from "../../assets/close-icon.png";
import { webNews } from '../../api/api';
class video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // videoList: [{
      //   id: 1,
      //   title: '如何让销售监管一步到位？',
      //   desc: '如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？'
      // }, {
      //   id: 2,
      //   title: '如何让销售监管一步到位？',
      //   desc: '如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？'
      // }, {
      //   id: 3,
      //   title: '如何让销售监管一步到位？',
      //   desc: '如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？如何让销售监管一步到位？'
      // }],
      isShow: false,
      homeNav: false,
      currentPage: 1,  //页码
      videoList: [],  //新闻列表
      totalPage: 1,  //总页数
      videoSrc: '', //视频播放地址
      webConfig: JSON.parse(localStorage.getItem("webConfig")) || null,
      loading: true
    }
  }
  videoSwitch(video) {
    this.setState({
      videoSrc: video,
      isShow: true
    });
  }
  componentDidMount() {
    window.scrollTo(0,0);
    this.getData(1);
  }
  scrollLink = (data) => {
    this.props.history.push({pathname:'/',query: {name: data}});
  }
  async getData(currentPage) {
    let parmas = {
        data: {
          menu_id: this.props.match.params.id,
          per_page: 10,
          page: currentPage
        }
    }
    let res = await webNews(parmas);
    // console.log(res)
    let { data, total } = res.data.data;
    setTimeout(() => {
      this.setState({
        videoList: data,
        totalPage: Math.ceil(total / 10),
        loading: false
      })
    }, 1000);
  }
  getCurrentPage(currentPage) {
    if( currentPage === this.state.currentPage ) {
      return;
    }
    this.setState({
      currentPage
    });
    this.getData(currentPage);
  }
  render() {
    let isVideo, content;
    if( this.state.isShow ) {
      isVideo = (
        <div className="video-fixed">
          <div className="video-box">
            <div className="closeVideo" onClick={() => {this.setState({ isShow: false })}}>
              <img src={closeIcon} alt="" />
            </div>
            <Player 
              autoPlay
              aspectRatio="4:1"
              src={this.state.videoSrc}
            />
          </div>
        </div>
      )
    }
    if( !this.state.loading ) {
      content = (
        <div className="container">
          <Helmet>
            <title>{this.state.webConfig.name} - 视频列表</title>
            <meta name="Keywords" content={this.state.webConfig.keyword} />
            <meta name="Description" content={this.state.webConfig.description} />
          </Helmet>
          <Nav 
            homeNav={this.state.homeNav} 
            scrollLink={this.scrollLink} 
          /> 
          <div className="video">
            <div className="w1200">
              <div className="container-title">
                <div className="title">视频列表</div>
                <div className="subtitle">VIDEO LIST</div>
              </div>
              <div className="video-list">
                {
                  this.state.videoList.map(item => {
                    return (
                      <div className="video-item" key={item.id}>
                        <div className="video-box">
                          <img src={item.cover} alt="" />
                          <div className="videoTitle">{item.title}</div>
                          <div className="play-video" onClick={this.videoSwitch.bind(this, item.content)}>
                            <img src={playIcon} alt="" />
                          </div>
                        </div>
                        <div className="title">{item.title}</div>
                        <div className="desc">{item.intro}</div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            {isVideo}
          </div>
          <div className="page">
            <Pagecomponent pageCallbackFn={this.getCurrentPage.bind(this)} totalPage={this.state.totalPage} currentPage={this.state.currentPage} />
          </div>
          <Footer />
        </div>
      )
    }else {
      content = (
        <Load />
      )
    }
    return (
      <div>{content}</div>
    );
  }
}

export default video;